import React from 'react'
import SecretComponent from './SecretComponent'

export default function Day3Home() {
  return (
    <div className='container'>
        <h1 className="my-3 text-danger" style={{"textAlign":"center"}}>Day - 3</h1>
        
        <p> Welcome to Day - 3 of mana potilu</p>
        <p> Main Aim of this potilu is to bring awareness about dsa to clg students, hope you guys help me to achieve this by sharing this as much as possible by keeping in your class groups and more</p>
        <h1 className="my-3 text-danger">Prizes</h1>
        <p> Every Day 3 prizes untai</p>
        <p> Clg First year Student First Prize Rs 200</p>
        <p> Clg 2nd,3rd year combined First Prize Rs 200</p>
        <p> Clg 4th year and passed out combined First Prize Rs 200</p>
        <p>So basical ga daily 3 prizes untai anamata, Mi id card chustham before giving prize to validate mi year.</p>
        <h1 className="my-3 text-danger">Results</h1>
        <p> Same day night 10pm ki mana Engineering Animuthyam youtube channel lo Results annouce chestham.</p>
        <h1 className="my-3 text-danger">Problem Statement</h1>
        <h2>Prathi word chadhuvu macha question, chala mandhi sagam sagam chadhuvuthunaru, so valaki correct ga ardham avatledhu</h2>
        <p>Ni goal enti ante oka google form link kanipetali. So aa google form link kanipetina tarvatha, aa google form ni browser lo open chesi ni details ivali. Evraithe first form fill chestharo vale winners anamata </p>
        <p>Example: Google form link general ga ila undhi anuko https://forms.gle/abcdefghijk</p>
         <p>nuvu kindha ichina tasks chesi "abcdefghijk" kanipetali anamata </p>
        <p> Nenu "abcdefghijk" ni 2 parts ga divide chesa ante "abcde" oka part , "fghijk" oka part anamata</p>
        <p> Task one solve chesthe first part osthadhi</p>
        <p> Task two solve chesthe second part osthadhi</p>
        <p><h3> Google form link ochesi "https://forms.gle/" + first part + second Part</h3></p>
        <p> All the best machas</p>

        <div>
          <h2> This form should be filled to participate. Compulsory <a target="_blank" href='https://forms.gle/knmwK1Ky7pbs2Gr1A'>To fill Form,click me</a></h2>
            <h2 className="my-3 text-danger">Task - 1</h2>
            <p> Oka pedha Hero athani movie lo "Alasyam ayindha acharya putra" ani dialogue use chesaru.</p>
            <p> So aa pedha hero, ee movie kante mundhu oka movie tisaru ,aa Movie name ni kindha box lo enter chey</p>
            <p>Hint aa movie lo dialogue: <br/>
              Pedha pedha godalu katedhi bayapadi, <br/>
               bayataki evadu pothadu ani kadhu, <br/>
              lopaliki evadu vasthadu ani</p>
              <p>aa Movie name ni kindha box lo enter chey</p>
            <p> Enter chesi submit click chey oka output osthadhi. Miru em input ichina edho oka output osthane untadhi. but miru correct input isthane correct output osthadhi, which is part 1 of our url</p>
            
        <SecretComponent/>
        </div>

        <div>
    <h2 className="my-3 text-danger">Task - 2</h2>
    <p>We have an array of numbers:</p>
    <p> [1746, 1234, 1567, 1987, 1741, 1456, 1678, 1900, 1789, 1345, 1600, 1800, 1810, 1500, 1700, 1950, 1771, 1400, 1650, 1850, 1805, 1300, 1550, 1750, 1763, 1250, 1500, 1700, 1741, 1350, 1600, 1800, 1811, 1450, 1650, 1850, 1781, 1200, 1500, 1700, 1797, 1300, 1600, 1900]
    </p>
    <p>The first number 1746 has index 0</p>
    <p>The second number 1234 has index 1</p>
    <p>The third number 1567 has index 2, and so on</p>
    <p>Now, create a new array by taking numbers whose index%4==0</p>
    <h5>Hint: If you don't know what % means, please watch this video for help <a target="_blank" href='https://youtu.be/9Ps6fXAgA-g?si=Mzz5NBvrf_OS0wFv'>Python YouTube link</a> or <a href='https://youtu.be/jpv8m0y-zpA?si=0ZabImkx1fCiIxBD'>Java YouTube link</a></h5>

    <p>Next, subtract 1691 from each number in this new array and store the resulting numbers in another array</p>
    <p>These numbers are ASCII values, so convert them into their corresponding ASCII characters</p>
    <h5>Hint: If you don't know what ASCII values are, please watch this video for help <a target="_blank" href='https://youtu.be/RUI-MQql8hc?si=shCbmgmz3CNcQlqx'>Python YouTube link</a> or <a href='https://youtu.be/yyyE6jn6W-s?si=AXn1cnSBUk7Fty_V'>Java YouTube link</a></h5>

    <p>Combine all these characters into a string</p>
    <p>Reverse the string</p>
    <p>Now, remove the first vowel character from the string. The remaining string is our Part 2 Output</p>
    <p>The Google form link is "https://forms.gle/" + first part + second part</p>

    <p>Example: Let's take an array of size 12:</p>
    <p>[1802, 100, 200, 300, 1796, 400, 500, 600, 1792, 700, 800, 900]</p>
    <p>The indices of these numbers are:</p>
    <p>1802 has index 0</p>
    <p>100 has index 1</p>
    <p>200 has index 2</p>
    <p>300 has index 3</p>
    <p>1796 has index 4</p>
    <p>400 has index 5</p>
    <p>500 has index 6</p>
    <p>600 has index 7</p>
    <p>1792 has index 8</p>
    <p>700 has index 9</p>
    <p>800 has index 10</p>
    <p>900 has index 11</p>
    <p>Indices where index%4==0 are 0, 4, and 8</p>
    <p>So we take 1802, 1796, and 1792 into the new array</p>
    <p>Now our new array is [1802, 1796, 1792]</p>
    <p>Subtract 1691 from each element: [1802 - 1691, 1796 - 1691, 1792 - 1691] = [111, 105, 101]</p>
    <p>These are ASCII values, so convert them into corresponding ASCII characters</p>
    <p>111 corresponds to 'o'</p>
    <p>105 corresponds to 'i'</p>
    <p>101 corresponds to 'e'</p>
    <p>Now, combine these characters into a string: 'oie'</p>
    <p>Reverse the string: 'eio'</p>
    <p>Now, remove the first vowel character from the string. The string 'eio' has 'e' as the first vowel</p>
    <p>Removing 'e' from 'eio' gives us 'io'</p>
    <p>So, the final Part 2 Output is 'io'</p>
</div>

        <br></br><br></br>
        
        <div><h2 className="my-3 text-danger">Best DSA in java course in telugu for 0 cost</h2> <a href='https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=DseHX9J8Zvhj-yJZ'> click me for that best free java course on youtube</a></div>
        <div><h2 className="my-3 text-danger">Best DSA in Python course in telugu for 0 cost</h2> <a href='https://www.youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP'> click me for that best free Python course on youtube</a></div>

        <div>   <h2 className="my-3 text-danger">RoadMap for Pedha pedha jobs</h2>
        <p>
        <br></br>

1)Dsa chala important. Nene free ga easy ga 5th class vadiki kuda ardhamaiyetatu dsa chepthunna youtube lo telugu lo.Engineering Animuthyam ani search chey vasthundhi youtube lo. Niku asal em ravasina avsaramledhu macha direct idhi start chesey.
<br></br>
2) Resume lo petkodaniki projects kavali so web development start cheyali.Html css Js nerchukovali. code with harry videos masthu untai web development midha
<br></br>
3)Html,css,js matrame saripovu ninu web developer ani pilvadaniki. MERN stack kuda nerchuko.Code with harry react course ani kotu pedha playlist osthadhi. Andhulo MERN motham nerpisthad with 3 projects ramp untadhi see.
<br></br>
4) Iga interview bit ane website lo CN,OS,SQl interview questions nerchuko
<br></br>
5)W3schools ane website lo sql nerchukoni, leetcode lo sql problems practice.
<br></br>
6) Follow step 1 its very important 
<br></br>
7) Ninu nuvu nammuu
<br></br>
All the best macha.</p></div>
    </div>
    
  )
}


const data = [
    {
        "step": 1,
        "title": "Basics of Java with DSA",
        "levels": [
            {
                "level": 1,
                "title": "Print Statement, If else Statement",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/iTTQaq22o1g?si=YkCV0uXBCT4Uh4Qt",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1udq5rRJ2fA0fXRbLMBypG97mUsQIeZcL/view?usp=drive_link",
                "problemLink": [
                    {
                        "title": "Online compiler",
                        "link": "https://www.programiz.com/java-programming/online-compiler/"
                    },
                    {
                        "title": "Print Statement",
                        "link": "https://www.hackerrank.com/challenges/welcome-to-java/problem?isFullScreen=true"
                    }
                ],
                "codes":[
                    {"title":"Print Statement Java",
                       "code":`class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Engineering Animuthyam");
    }
}`
                    },
                    {"title":"data types, variables and if else",
                        "code":`

class HelloWorld {
    public static void main(String[] args) {
        String Thop = "Mahesh Babu";
        int Alluarjun = 1;
        
        System.out.println(Alluarjun);
        System.out.println(Thop)
     if(1+5 == 6){
         System.out.println("Pawan Kalyan");
         System.out.println("Hero")
     }else{
        System.out.println("Sunil"); 
     }
     
     
     
    }
}`
                     }
,{"title":"If else helping animuthyam boy girl",
                       "code":`class HelloWorld {
    public static void main(String[] args) {
String animuthyam = "girl";
     
     if(animuthyam.equals("boy")){
         System.out.println("left");
     }else{
         System.out.println("right");
     }
    }
}`
                    }          ]
            },
 {
                "level": 2,
                "title": "Arrays, For Loop",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/YWYy905lo9I?si=kODR5sFHARTEFLCd",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1beiosGch-bi07QHx0UlgsvlhXQ0lv6GM/view?usp=sharing",
     "problemLink": []
 },
 {
                "level": 3,
                "title": "Modulus Operator, And Or ( && , || ) operators",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/jpv8m0y-zpA?si=crWm9X7A9FJBbDcX",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1gRCSb6wbQa5OnxCyIbMLpolBKTPLAhwz/view?usp=sharing",
     "problemLink": []
 },
            {
                "level": 4,
                "title": "Reversing a String, Palindrome, Boolean datatype, break",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/RHkMozc9lJw?si=R3RaP6gIpzFmcWBE",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/15BGzPKiCBy2hS8uTwA084oqoOkyqlv7q/view?usp=sharing",
     "problemLink": []
 },
             {
                "level": 5,
                "title": "Functions",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/84yfW56Hti8?si=T2MEu8Bt5TqHy-0k",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1U5UxWBCXIXxx8QiQlzkq2Bxqqfe_i88u/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 6,
                "title": "Ascii values, type casting, adjacent",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/yyyE6jn6W-s?si=k-vHqmE9QOi7jOsO",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1l-FohQjf6up9rOPP1McCNMXDu6c0rs1e/view?usp=sharing",
     "problemLink": []
 },
             {
                "level": 7,
                "title": "3 Leetcode problems arrays strings",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/ZguzDCKg_co?si=gQ544ucjLHyb-Pkb",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/10GSzAKmM1qAKbHIWCJ56qV48CTvWkf-v/view?usp=sharing",
     "problemLink": []
 },
 {
                "level": 8,
                "title": "nested double for loop 2 leetcode problems",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/TalGA8j1fEk?si=F16g8X_NR7Ewk4FO",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1Cz5ykISBWE0wPMl466Nd4T81slul8WhE/view?usp=sharing",
     "problemLink": []
 },
            {
                "level": 9,
                "title": "Patterns ( Very Important to Improve Logic, double for loops)",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/84yfW56Hti8?si=LeBD4zVUz-KNUeDW",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1D7jqjgqMx8E6Ut4KIgBgQ1UEokZTHDuP/view?usp=sharing",
     "problemLink": []
 },
              {
                "level": 10,
                "title": "Patterns - 2( Very Important to Improve Logic, double for loops)",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/nobQAjHkFfc?si=J-v9ReZjmXnzCwl8",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1CjB0qF7HiGGZu-FD3q8okiTdDGZVl_1l/view?usp=sharing",
     "problemLink": []
 },
             {
                "level": 11,
                "title": "Time Complexity, Best time to Buy and Sell Stocks.",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/AdmYGx_MCoQ?si=HyArpjFimLthm7Ic",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1tV09Hv8Z3Mg8JEH4gQVf7Ui9NE0ZXat6/view?usp=sharing",
     "problemLink": []
 },{
                "level": 12,
                "title": "3 leetcode problems | Arrays .",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/AdmYGx_MCoQ?si=HyArpjFimLthm7Ic",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1vk-G-saPyjfP2xFQ-s8anZI5lMEUQrK-/view?usp=drivesdk",
     "problemLink": []
 },
             {
                "level": 13,
                "title": "Inputs in Java",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/kptqdWpqtrg?si=V4bMVyhB8jDZ010d",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1sG77Kjl5J9QcwYKeKvZXtsO8CRz5NRTU/view?usp=sharing",
     "problemLink": []
 },{
                "level": 14,
                "title": "Bubble Sort | Arrays Sort.",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/L6ep7AxyOLg?si=Pg-2nqCeBcUUO4CJ",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1CVobBXvHnAIxXToTbUoDute_Tqd8juDp/view?usp=sharing",
     "problemLink": []
 },
              {
                "level": 15,
                "title": "HashMap in Java",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/11SkIS3pI70tlGHrA2OOllpOOSh2TmtqJ/view?usp=sharing",
     "problemLink": []
 },{
                "level": 16,
                "title": "HashMap part - 2, Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/19NAAJcs3r52TLHEpZEE54W_ZKsyrB5oS/view?usp=sharing",
     "problemLink": []
 },
            {
                "level": 17,
                "title": "Leetcode problems on HashMap",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1kr1NWroyGaDWBRTgwLyapCXdprOrBQAT/view?usp=sharing",
     "problemLink": []
 },
               {
                "level": 18,
                "title": "Leetcode problems on HashMap, Urra mass problems",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1z2Rp2UyED8CHb3YKa4VauWwABQ6ByLFk/view?usp=sharing",
     "problemLink": []
 },
            
               {
                "level": 19,
                "title": "Leetcode problems on HashSet",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1TOWcJbmy3HB0WbIjUvJiv3kfmMXcnQC5/view?usp=sharing",
     "problemLink": []
 },
            
               {
                "level": 20,
                "title": "2D Arrays",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/14-blUrnci5ohOZgHEIwqnigNYz9XCveT/view?usp=sharing",
     "problemLink": []
 },
            
            
               {
                "level": 21,
                "title": "2D Arrays Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1smOcUV6-Pm4kxXm_eDbuE3WWzmb3aCYp/view?usp=sharing",
     "problemLink": []
 },
             {
                "level": 22,
                "title": "ArrayList, Pascals Triangle Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1Hj4e4vuzkRtoS2Hl9Q5rYBwWqt21orX-/view?usp=sharing",
     "problemLink": []
 }
            
            
        ]
    },
        {
            "step": 2,
            "title": "Sliding Window and Two Pointers",
            "levels": [
               {
                "level": 23,
                "title": "Substring, Subarray, subsequence",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1agUmTMhLTYUAoylUcN1DrSCitq3irGQZ/view?usp=sharing",
     "problemLink": []
 },
                 {
                "level": 24,
                "title": "Problem: Max sum of 3 length subArray",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1SWz4lyl9WxlNKPNtx9-dBoVrgu-na9T3/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 25,
                "title": "Sliding Window Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1XTBglDDGnarpCdiOAJKmkmyRd8Xdwj7j/view?usp=sharing",
     "problemLink": []
 },
     
                 {
                "level": 26,
                "title": "Problem: Substring of size 3 with distinct characters",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1MKi-YFGM2J6ukNHY0evQwxzuvRUrgmkv/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 27,
                "title": "Problem: Substring of size 3 with distinct characters",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1SrsoozuRyKtkkXdA9cKhTKLqdeH0oFPq/view?usp=sharing",
     "problemLink": []
 },{
                "level": 28,
                "title": "Sliding Window Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1fGzC3hh0KZb0HA-8hzo_j86KcZR4LfZJ/view?usp=sharing",
     "problemLink": []
 },
     
                 {
                "level": 29,
                "title": "Leetcode Problems related to sorting",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1hVCHc6YJu5RwVy9c6DJoiDmfrU4T7P6u/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 30,
                "title": "Sliding Window and Two pointers Problems",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=rarXL3hv0Yg2xD9Q",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1eion0I6jyr5Ms0XNxrCBG4SaJfyaYpgE/view?usp=sharing",
     "problemLink": []
 },
             
            ]
        },
        {
            "step": 3,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 4,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 5,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 6,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 7,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 8,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 9,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 10,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        }
    ]
    
export default data;

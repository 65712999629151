import React from 'react'
import SecretComponent from './SecretComponent'

export default function Day1Home() {
  return (
    <div className='container'>
        <h1 className="my-3 text-danger" style={{"textAlign":"center"}}>Day - 1</h1>
        
        <p> Welcome to Day - 1 of mana vinayaka chavithi potilu</p>
        <p> Main Aim of this potilu is to bring awareness about dsa to clg students, hope you guys help me to achieve this by sharing this as much as possible by keeping in your class groups and more</p>
        <h1 className="my-3 text-danger">Prizes</h1>
        <p> Every Day 3 prizes untai</p>
        <p> Clg First year Student First Prize Rs 100</p>
        <p> Clg 2nd,3rd year combined First Prize Rs 100</p>
        <p> Clg 4th year and passed out combined First Prize Rs 100</p>
        <p>So basical ga daily 3 prizes untai anamata, Mi id card chustham before giving prize to validate mi year.</p>
        <h1 className="my-3 text-danger">Results</h1>
        <p> Same day evening 8pm ki mana Engineering Animuthyam youtube channel lo Results annouce chestham.</p>
        <h1 className="my-3 text-danger">Problem Statement</h1>
        <h3>Day 1 kadha lets start with easy questions anamata</h3>
        <p>Ni goal enti ante oka google form link kanipetali. So aa google form link kanipetina tarvatha, aa google form ni browser lo open chesi ni details ivali. Evraithe first form fill chestharo vale winners anamata </p>
        <p>Example: Google form link general ga ila undhi anuko https://forms.gle/abcdefghijk</p>
         <p>nuvu kindha ichina tasks chesi "abcdefghijk" kanipetali anamata </p>
        <p> Nenu "abcdefghijk" ni 2 parts ga divide chesa ante "abcde" oka part , "fghijk" oka part anamata</p>
        <p> Task one solve chesthe first part osthadhi</p>
        <p> Task two solve chesthe second part osthadhi</p>
        <p> Google form link ochesi "https://forms.gle/" + first part + second Part</p>
        <p> All the best machas</p>

        <div>
            <h2 className="my-3 text-danger">Task - 1</h2>
            <p> Recent ga oka pedha hero MLA ga gelicharu</p>
            <p> AA pedha hero insta account open chesi,Account lo first chesina post or reel lo Oka 4 words heading untadhi,ending with "forever", AA heading lo First Word ni kindha box lo enter chey</p>
            <p> Enter chesi submit click chey oka output osthadhi. Miru em input ichina edho oka output osthane untadhi. but miru correct input isthane correct output osthadhi, which is part 1 of our url</p>
            
        <SecretComponent/>
        </div>

        <div>
            <h2 className="my-3 text-danger">Task - 2</h2>
            <p> Mana degara oka array of numbers unai anamata</p>
            <p> [119, 107, 54, 99, 98, 97, 70, 121, 118, 102, 54]</p>
            <p> E paina array lo unavi ascii values anamata, so a numbers ni corresponding ascii characters laga marchu</p>
            <p> A characters anitini kalipesey oka string avthundhi adhe mana Part 2 Output anamta</p>
            <p> Google form link ochesi "https://forms.gle/" + first part + second Part</p>
        </div>
        <br></br><br></br>
        
        <div><h2 className="my-3 text-danger">Best Free DSA in java course in telugu</h2> <a href='https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=DseHX9J8Zvhj-yJZ'> click me for that best free java course on youtube</a></div>
        <div><h2 className="my-3 text-danger">Best Free DSA in Python course in telugu</h2> <a href='https://www.youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP'> click me for that best free Python course on youtube</a></div>

        <div>   <h2 className="my-3 text-danger">RoadMap for Pedha pedha jobs</h2>
        <p>
        <br></br>

1)Dsa chala important. Nene free ga easy ga 5th class vadiki kuda ardhamaiyetatu dsa chepthunna youtube lo telugu lo.Engineering Animuthyam ani search chey vasthundhi youtube lo. Niku asal em ravasina avsaramledhu macha direct idhi start chesey.
<br></br>
2) Resume lo petkodaniki projects kavali so web development start cheyali.Html css Js nerchukovali. code with harry videos masthu untai web development midha
<br></br>
3)Html,css,js matrame saripovu ninu web developer ani pilvadaniki. MERN stack kuda nerchuko.Code with harry react course ani kotu pedha playlist osthadhi. Andhulo MERN motham nerpisthad with 3 projects ramp untadhi see.
<br></br>
4) Iga interview bit ane website lo CN,OS,SQl interview questions nerchuko
<br></br>
5)W3schools ane website lo sql nerchukoni, leetcode lo sql problems practice.
<br></br>
6) Follow step 1 its very important 
<br></br>
7) Ninu nuvu nammuu
<br></br>
All the best macha.</p></div>
    </div>
    
  )
}

import React from 'react';

export default function Time({ value, onChange }) {
  return (
    <div className="form-group" style={{width:"150px", "backgroundColor":"#422846",color:"white"}}>
      <select 
        className="form-control" 
        value={value} 
        onChange={(e) => onChange(e.target.value)}
        style={{"backgroundColor":"#422846",color:"white"}}
      >
        <option value="chala"  style={{"backgroundColor":"#422846",color:"white"}}>Chala Time</option>
        <option value="moderate"  style={{"backgroundColor":"#422846",color:"white"}}>Moderate Time</option>
        <option value="konchame"  style={{"backgroundColor":"#422846",color:"white"}}>Konchame Time</option>
      </select>
    </div>
  );
}

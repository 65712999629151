import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import DSASheetDataPage from './DSASheetDataPage'
import PlacementData from "./PlacementData";
import PythonPlacmentData from "./PythonPlacementData"
import {  Routes, Route } from "react-router-dom"; 
import CodePage from './CodePage';
import Day1Home from '../quizGanapti/day1/Day1Home';
import Day2Home from '../quizGanapti/day2/Day2Home';
import Day3Home from '../quizGanapti/day3/Day3Home';



export default function PlacementHome({existsInSet, removeFromSet,addToSet, getSetLength, localStorageName, setlocalStorageName}) {
  

  const [changed, setchanged] = useState(0);

 
  return (
    <div >
        <NavBar/>
       

        <Routes>
    <Route path="/python" element={<DSASheetDataPage langmini={"Python"} setlocalStorageName={setlocalStorageName} lang={"muthyampython"} existsInSet={existsInSet} removeFromSet={removeFromSet} addToSet={addToSet} PlacementData={PythonPlacmentData} setchanged={setchanged} changed={changed} localStorageName={localStorageName} getSetLength={getSetLength}/>} />
 
    <Route path="/" element={<DSASheetDataPage langmini={"Java"} setlocalStorageName={setlocalStorageName} lang={"muthyam"} existsInSet={existsInSet} removeFromSet={removeFromSet} addToSet={addToSet} PlacementData={PlacementData} setchanged={setchanged} changed={changed} localStorageName={localStorageName} getSetLength={getSetLength}/>} />
    <Route path="/code/:id" element={<CodePage />} />
    

    {/* Routes for quiz vinayakachavithi */}
    <Route path="/day1" element={<Day1Home/>}/>
    <Route path="/macha2" element={<Day2Home/>}/>
    <Route path="/boom3" element={<Day3Home/>}/>
        
        </Routes>
    </div>
  )
}

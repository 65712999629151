import React from 'react'
import SecretComponent from './SecretComponent'

export default function Day2Home() {
  return (
    <div className='container'>
        <h1 className="my-3 text-danger" style={{"textAlign":"center"}}>Day - 2</h1>
        
        <p> Welcome to Day - 2 of mana vinayaka chavithi potilu</p>
        <p> Main Aim of this potilu is to bring awareness about dsa to clg students, hope you guys help me to achieve this by sharing this as much as possible by keeping in your class groups and more</p>
        <h1 className="my-3 text-danger">Prizes</h1>
        <p> Every Day 3 prizes untai</p>
        <p> Clg First year Student First Prize Rs 100</p>
        <p> Clg 2nd,3rd year combined First Prize Rs 100</p>
        <p> Clg 4th year and passed out combined First Prize Rs 100</p>
        <p>So basical ga daily 3 prizes untai anamata, Mi id card chustham before giving prize to validate mi year.</p>
        <h1 className="my-3 text-danger">Results</h1>
        <p> Same day evening 9pm ki mana Engineering Animuthyam youtube channel lo Results annouce chestham.</p>
        <h1 className="my-3 text-danger">Problem Statement</h1>
        <h2>Prathi word chadhuvu macha question, chala mandhi sagam sagam chadhuvuthunaru, so valaki correct ga ardham avatledhu</h2>
        <p>Ni goal enti ante oka google form link kanipetali. So aa google form link kanipetina tarvatha, aa google form ni browser lo open chesi ni details ivali. Evraithe first form fill chestharo vale winners anamata </p>
        <p>Example: Google form link general ga ila undhi anuko https://forms.gle/abcdefghijk</p>
         <p>nuvu kindha ichina tasks chesi "abcdefghijk" kanipetali anamata </p>
        <p> Nenu "abcdefghijk" ni 2 parts ga divide chesa ante "abcde" oka part , "fghijk" oka part anamata</p>
        <p> Task one solve chesthe first part osthadhi</p>
        <p> Task two solve chesthe second part osthadhi</p>
        <p><h3> Google form link ochesi "https://forms.gle/" + first part + second Part</h3></p>
        <p> All the best machas</p>

        <div>
            <h2 className="my-3 text-danger">Task - 1</h2>
            <p> Oka pedha Hero athani movie lo "Thagedhe lee" ani dialogue use chesaru. Aa movie heroine national crush ani kuda antaru</p>
            <p> So aa pedha Hero tisina first movie(as Main Lead), Movie name ni kindha box lo enter chey</p>
            <p> Enter chesi submit click chey oka output osthadhi. Miru em input ichina edho oka output osthane untadhi. but miru correct input isthane correct output osthadhi, which is part 1 of our url</p>
            
        <SecretComponent/>
        </div>

        <div>
            <h2 className="my-3 text-danger">Task - 2</h2>
            <p> Mana degara oka array of numbers unai anamata</p>
            <p> [260, 262, 211, 257, 263, 254, 218, 254, 242, 253, 265, 253, 256, 246, 193, 246, 208, 246]</p>
            <p>First number 260 index emo 0 anamata</p>
            <p>Second number 262 index emo 1 anamata</p>
            <p>Thrid number 211 index emo 2 anamata and so on</p>
            <p>Ippudu oka kotha array create chey by taking numbers, whose index%2==0</p>
            <h5>Hint: Miku % ante telvakpothe e video chudandi will help <a href='https://youtu.be/9Ps6fXAgA-g?si=Mzz5NBvrf_OS0wFv'> Python Youtube link</a> Or <a href='https://youtu.be/jpv8m0y-zpA?si=0ZabImkx1fCiIxBD'> Java Youtube Link</a></h5>

            <p>Ippudu e kotha array lo okkoka number nundi 143 subtract chesi, ochina numbers ni inkoka array lo rasko macha</p>
            <p> E kotha array lo unavi ascii values anamata, so a numbers ni corresponding ascii characters laga marchu</p>
            <h5>Hint: Miku Ascii values ante telvakpothe e video chudandi will help <a href='https://youtu.be/RUI-MQql8hc?si=shCbmgmz3CNcQlqx'> Python Youtube link</a> Or <a href='https://youtu.be/yyyE6jn6W-s?si=AXn1cnSBUk7Fty_V'> Java Youtube Link</a></h5>

            <p> A characters anitini kalipesey oka string avthundhi adhe mana Part 2 Output anamta</p>
            <p> Google form link ochesi "https://forms.gle/" + first part + second Part</p>

            <p>Example: Example kosam [260,262,211, 257] tiskundham idhi just example, assalaina array paina undhi</p>
            <p>ippueu vati index ochesi</p>
            <p>260 ki 0</p>
            <p>262 ki 1</p>
            <p>211 ki 2</p>
            <p>257 ki 3</p>
            <p>dintlo 0%2==0 and 2%2==0 so 260 , 211 ni kotha array loki taking</p>
            <p>Ippudu mana kotha array [260,211]</p>
            <p>ippudu prathi dantlo 143 subtract chesthe [260-143,211-143] = [117, 68]</p>
            <p>ippudu mana kotha array [117, 68 ]</p>
            <p>Ivi ascii values ayithe dhani ascii character ochesi</p>
            <p>117 ki u</p>
            <p>68 ki D</p>
            <p> so final ga mana part2 = "uD" aythadhi according to this example.but try with original array </p>
        </div>
        <br></br><br></br>
        
        <div><h2 className="my-3 text-danger">Best Free DSA in java course in telugu</h2> <a href='https://youtube.com/playlist?list=PLjzLBp9HHZWhVXBSPS1VqxXXDoVk07gd9&si=DseHX9J8Zvhj-yJZ'> click me for that best free java course on youtube</a></div>
        <div><h2 className="my-3 text-danger">Best Free DSA in Python course in telugu</h2> <a href='https://www.youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP'> click me for that best free Python course on youtube</a></div>

        <div>   <h2 className="my-3 text-danger">RoadMap for Pedha pedha jobs</h2>
        <p>
        <br></br>

1)Dsa chala important. Nene free ga easy ga 5th class vadiki kuda ardhamaiyetatu dsa chepthunna youtube lo telugu lo.Engineering Animuthyam ani search chey vasthundhi youtube lo. Niku asal em ravasina avsaramledhu macha direct idhi start chesey.
<br></br>
2) Resume lo petkodaniki projects kavali so web development start cheyali.Html css Js nerchukovali. code with harry videos masthu untai web development midha
<br></br>
3)Html,css,js matrame saripovu ninu web developer ani pilvadaniki. MERN stack kuda nerchuko.Code with harry react course ani kotu pedha playlist osthadhi. Andhulo MERN motham nerpisthad with 3 projects ramp untadhi see.
<br></br>
4) Iga interview bit ane website lo CN,OS,SQl interview questions nerchuko
<br></br>
5)W3schools ane website lo sql nerchukoni, leetcode lo sql problems practice.
<br></br>
6) Follow step 1 its very important 
<br></br>
7) Ninu nuvu nammuu
<br></br>
All the best macha.</p></div>
    </div>
    
  )
}

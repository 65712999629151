import React, { useEffect, useState } from 'react'
import CodeItem from './CodeItem'
import { useParams } from 'react-router-dom';

import PlacementData from "./PlacementData";
import PythonPlacementData from "./PythonPlacementData"
export default function CodePage() {
    let { id } = useParams();
    let parts = id.split('-');
    let firstNumber = parseInt(parts[0], 10)-1; 
    let secondNumber = parseInt(parts[1], 10)-1; 
    let third= parts[2];

    const [fetched_codeall, setfetched_codeall] = useState([])

    useEffect(() => {
      if(third=== "Java"){
        setfetched_codeall(PlacementData?.[firstNumber]?.["levels"]?.[secondNumber]?.["codes"]);
      }else{
        setfetched_codeall(PythonPlacementData?.[firstNumber]?.["levels"]?.[secondNumber]?.["codes"]);

      }
    }, [])
    
    const ValidCompoenent =() => {
        return (
    
        <div className='my-3'>
        <h1 className="my-3 text-danger" style={{"textAlign":"center"}}>Code</h1>

    { 

fetched_codeall.length>0 && fetched_codeall.map((single_code)=>{
 

 return <CodeItem key={single_code._id} code={single_code.code} title={single_code.title}_id={single_code._id}/>
})
}
    
</div>
    )
}

    const ComingSoon = () => {
        return (
          <div style={styles.container} className='my-3'>
            Inka code upload cheyale mawa dhiniki. Coming soon.
          </div>
        );
      };
      
      // You can adjust the styles according to your application's design
      const styles = {
        container: {
          padding: '20px',
          margin: '20px',
          backgroundColor: 'black',
          textAlign: 'center',
          color: '#333',
          fontSize: '18px',
          borderRadius: '8px',
          color:"white"
        }
      };
    
      return (
        
        <>
        {fetched_codeall && fetched_codeall.length!==0 && <ValidCompoenent/>}
        {(!fetched_codeall || fetched_codeall?.length===0) && <ComingSoon/>}
        </>
      )
   
  
}

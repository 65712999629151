import React, { useState } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function CodeItem(props) {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    };
  
  return (
    <div>
        <div className='container my-3'>
<div className="form-floating">

  <div className="card-body">
    <div className='d-flex  align-items-center'style={{"position":"relative" , "left":"-7px"}}>
    <h5 className="card-title mx-2 my-2 text-danger" style={{"textAlign":"left"}}>{props.title}</h5>
  </div>
   
  </div>


<CopyToClipboard text={props.code} onCopy={handleCopy}>
        <button className='btn btn-info my-2'> 
        <i className="fas fa-copy"></i>
          {copied ? ' Copied!' : ' Copy'}
        </button>
      </CopyToClipboard>
  <SyntaxHighlighter language='java' style={dracula}>

      {props.code}
    </SyntaxHighlighter>
  
</div>
</div>
<hr></hr>
    </div>
  )
}


const data = [
    {
        "step": 1,
        "title": "Basics of Python with dsa",
        "levels": [
            {
                "level": 1,
                "title": "Print Statement, If else Statement",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/Kp22-yzUkbQ?si=TU6Va1uVmgSBrFHU",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1sjXkiWEZEZ4erYTF2xjVFIObnHnvmPtw/view?usp=drivesdk",
                "problemLink": []
            },{
                "level": 2,
                "title": "Arrays, For Loop",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/zassUzrHRIA?si=VuFkKiqkLd1UMyyw",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1v3lObkZrTYccx82lVIjrjTroVfGC0I-Z/view?usp=sharing",
     "problemLink": []
 },
 {
                "level": 3,
                "title": "Modulus Operator, And Or operators",
                "time": "konchame",
                "youtubeLink": "https://youtu.be/9Ps6fXAgA-g?si=p-lHSYryxknXkbnc",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1g59t9MHrDEZLbsXU3FnlAV4Y_-YvNp_5/view?usp=sharing",
     "problemLink": []
 },
    
 {
                "level": 4,
                "title": "Reversing a String, Palindrome, Boolean datatype, break",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1M46IfbbDvhraggoen16X-9g6SuJg-QK_/view?usp=sharing",
     "problemLink": []
 },
            
 {
                "level": 5,
                "title": "Functions",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1L0V2i1JFwkATPMF7OdjzFibo0yX3Oi3m/view?usp=sharing",
     "problemLink": []
 },
            
 {
                "level": 6,
                "title": "Ascii values, Leetcode problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1m6Yr7RxWeWG2Jdecgg0mWDyrnmIur_Nk/view?usp=sharing",
     "problemLink": []
 },
            
 {
                "level": 7,
                "title": "3 Leetcode problems arrays strings",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1HOUD-7rS9WyPcB_Pjcu5oKSlEWUCAppk/view?usp=sharing",
     "problemLink": []
 },
            
 {
                "level": 8,
                "title": "Double For Loop Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1HQx06oLwWUC3x0LAIfYnDoZPSiyIRs0h/view?usp=sharing",
     "problemLink": []
 },
            
            
 {
                "level": 9,
                "title": "2D Lists",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1xm2M2JlTb23RqaYaupIsViJoCoRdMLyt/view?usp=sharing",
     "problemLink": []
 },
            
            
            
 {
                "level": 10,
                "title": "Patterns",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1hnHqn1wnZaRWcGUcaO1TGFxecwwNcneO/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 11,
                "title": "Patterns",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1LE4VbZ0Cbkzbyu_vlPw_u-lz4o6TRMUR/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 12,
                "title": "Patterns",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1MSAQvHMcJpQpdu3TTi_9eFcxn-54rzqn/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 13,
                "title": "Patterns",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1gO0KfU_Cuf01wyYS2w3SejUYBMKzy2Z6/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 14,
                "title": "Time Complexity",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1UU9DWExlBaicqmybrWtg5R3ZDFWuAS7-/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 15,
                "title": "Time Complexity and Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1uGHuJ5SAikZ6ZM7RylZPB2rU77CfGCcr/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 16,
                "title": "Problem: Maximum Difference between Increasing Elements",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1Po3gdg12P3pxag85IjS4aoEv9gbAZuo4/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 17,
                "title": "Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/10kfKfj1xwYdxvopaQSF4WRgEX8meoY2B/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 18,
                "title": "Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1jfPWNKyp4FijBLvj1KKjT7gIALQGEBzV/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 19,
                "title": "Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/17fRBtSHpesQJx7frlXcfXwQR_BincciP/view?usp=sharing",
     "problemLink": []
 }, {
                "level": 20,
                "title": "Leetcode Problem",
                "time": "konchame",
                "youtubeLink": "https://youtube.com/playlist?list=PLjzLBp9HHZWiJrhfJzTAEbwdpQIfUXtwP&si=onlAxkf8dhuuk46r",
                "finished": false,
                "notesLink": "https://drive.google.com/file/d/1E-4mLxKZGDWQbICcn4024l7C51FyLVGE/view?usp=sharing",
     "problemLink": []
 }
            
        ]
    },
        {
            "step": 2,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 3,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 4,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 5,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 6,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 7,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 8,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 9,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        },
        {
            "step": 10,
            "title": "Coming Soon",
            "levels": [
                {
                    "level": 1,
                    "title": "Coming Soon",
                    "time": "Coming Soon",
                    "youtubeLink": "https://www.youtube.com/@engineeringanimuthyam/videos",
                    "finished": false,
                    "notesLink": "",
                    "problemLink": [
                        {
                            "title": "Coming Soon",
                            "link": ""
                        }
                    ]
                }
            ]
        }
    ]
    
export default data;

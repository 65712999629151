import React from 'react'

export default function EndingMawa() {
  return (
    <div style={{"backgroundColor":"#000000",height:"60vh",color:"white",alignItems:"center"}} className='text-center' >
        <h3>BOOOOOOOMMMMMM Emledh mawa ikkada</h3>
        <h3>Nuv success avthav mawa ee enthusiasm to explore, will take you to top</h3>
        <h5>-Made with love by Engineering Animuthyam❤️</h5>
        </div>
    
    )
}

import React from 'react'
import TableRow from './TableRow'

export default function Accordian({langmini, item, existsInSet, removeFromSet, addToSet, setchanged, changed, timeMap, time}) {
  return (
    <div className="accordion-item" style={{"backgroundColor":"#422846"}}>
    <h2 className="accordion-header" style={{"backgroundColor":"#301934"}}>
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+item.step} aria-expanded="true" aria-controls={"collapse"+item.step} style={{"backgroundColor":"#301934",color:"white"}}>
       <b> Step-{item?.step} {item?.title}</b>
      </button>
    </h2>
    <div id={"collapse"+item.step} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <div className="table-responsive">
      <table className="table">
  <thead >
    <tr style={{"backgroundColor":"#422846"}}>
      <th style={{"backgroundColor":"#422846",color:"white"}} scope="col">Level</th>
      <th style={{"backgroundColor":"#422846" ,color:"white"}} scope="col">Title</th>
      <th style={{"backgroundColor":"#422846" ,color:"white"}} scope="col">Youtube</th>
      <th style={{"backgroundColor":"#422846" ,color:"white"}} scope="col">Notes</th>
      <th style={{"backgroundColor":"#422846" ,color:"white"}} scope="col">Link</th>
      <th style={{"backgroundColor":"#422846" ,color:"white"}} scope="col">Code Macha</th>
    </tr>
  </thead>
  <tbody>
   {item.levels.map((data)=>{
    if((!(data.time in timeMap)) || (timeMap[time]<=timeMap[data.time])){
    return <TableRow data={data} existsInSet={existsInSet} removeFromSet={removeFromSet} addToSet={addToSet} keyId={item.step+"-"+data.level+"-"+langmini} setchanged={setchanged} changed={changed}/>
}})}
    
  </tbody>
</table>
      </div>
    </div>
  </div>
  </div>
  )
}


  
  
import React, { useEffect, useState } from 'react'
import ProblemLink from './ProblemLink'
import { Link } from 'react-router-dom';

export default function TableRow({data, existsInSet, removeFromSet,addToSet,keyId, setchanged, changed}) {

  const [isChecked, setIsChecked] = useState(false);


  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
    if(isChecked){
        removeFromSet(keyId);
    }else{
        addToSet(keyId);
    }
    setchanged(changed+1)
  };

  useEffect(() => {
    console.log("vignesh",(existsInSet(keyId)),keyId);
    if(existsInSet(keyId)){
      setIsChecked(true);
    }
  }, [])
  

  return (
    <tr>
    <th style={{"backgroundColor":"#422846", color:"white"}} scope="row"><input class="form-check-input mx-2" type="checkbox" value="" id="flexCheckDefault" onClick={handleCheckboxClick} checked={isChecked}/>{data.level}</th>
    <td style={{"backgroundColor":"#422846", color:"white"}} >{data.title}</td>
    <td style={{"backgroundColor":"#422846", color:"white"}}><a href={data.youtubeLink} rel='noreferrer' target="_blank"> <img alt='youtubelogo' height="75vw" src='/yt.png'></img></a></td>
    <td style={{"backgroundColor":"#422846", color:"white"}}><a href={data.notesLink} rel='noreferrer' target="_blank"> <h2>📒</h2></a></td>
    <td style={{"backgroundColor":"#422846", color:"white"}}>{data.problemLink.map((link)=>{return <ProblemLink link={link}/>})}</td>
    <td style={{"backgroundColor":"#422846", color:"white"}}><Link to={"/code/"+keyId} target="_blank">  <img alt='youtubelogo' height="75vw" src='/code.png'></img></Link></td>
  </tr>
  )
}

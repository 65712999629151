import React, { useState } from 'react';

const SecretComponent = () => {
  const [input, setInput] = useState('');
  const [message, setMessage] = useState('');

  function mapMemoriesToOutput(event) {
    event.preventDefault();
    const charMap = {
      'a': ['D', 'A'], 
      't': ['m'],       
      'i': ['m'],       
      'r': ['u'],   
      'g': ['a',"M"],       
        'n':["1"],
        'o':["7"]
    };


    let result = '';
    let charCount = {};


        for (let i = 0; i < input.length; i++) {
            const currentChar = input[i].toLowerCase();

            // Initialize count for the character if not already initialized
            if (!charCount[currentChar]) {
                charCount[currentChar] = 0;
            }

            // Get the correct character from the charMap using the count as the index
            const mappedChar = charMap[currentChar]?.[charCount[currentChar]] || '';
            result += mappedChar;

            // Increment the count for the current character
            charCount[currentChar]++;
        }
        setMessage(result);
    


}




  const handleInputChange = (event) => {
    setInput(event.target.value);
  };



  return (
    <div className="container mt-5 bg-dark text-white">
    <form onSubmit={mapMemoriesToOutput} className="mb-3">
      <div className="form-group">
        <label className="form-label">
          Enter cheyu macha aa word, exactly same enter chey:
          <input type="text" className="form-control" value={input} onChange={handleInputChange} />
        </label>
        <br></br>
        <button type="submit" className="btn btn-primary mt-2">Submit</button>
      </div>
    </form>
    <p>Mir ichina input ki ochina output: {(message || message.length !== 0) ? message : "boom"}</p>
  </div>
  );
};

export default SecretComponent;

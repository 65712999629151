import React from 'react'

export default function NavBar() {
  return (
    <div><nav className="navbar navbar-expand-lg navbar-dark" style={{"backgroundColor":"#000000", color:"white"}}>
    <div className="container-fluid" style={{ color:"white"}}>
      <a className="navbar-brand" style={{color:"white"}} rel='noreferrer' target="_blank" href="https://www.youtube.com/@engineeringanimuthyam/videos"><h3>Engineering Animuthyam</h3></a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
         
         <p className='text-center fw-bold text-danger pt-3' style={{"width":"65vw"}}><h4>Ni kante thop evar ler macha, Nuv ankunte em cheyagalguthavo chupidham</h4></p>
        <ul className="navbar-nav  ">

        <li className="nav-item pt-2">
            <a className="nav-link"  rel='noreferrer' target="_blank"  aria-current="page" href="https://www.instagram.com/engineering_animuthyam/"><img alt='instagram' height="25vh" src='/insta.png'></img></a>
          </li>
          <li className="nav-item">
            <a className="nav-link"   rel='noreferrer' target="_blank"  href="https://www.whatsapp.com/channel/0029Vaepy0G2phHD8zURlW0R"><img alt='whatsapp' height="45vh" src='/whatsapp.png'></img></a>
          </li>
          </ul>
        {/* <form className="d-flex" role="search">
          <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
          <button className="btn btn-outline-success" type="submit">Search</button>
        </form> */}
      </div>
    </div>
  </nav></div>
  )
}

import React, { useEffect, useState } from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

export default function ProgressiveBar({width,percentage,localStorageName}) {
    const [isMobile, setIsMobile] = useState(false);

      useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth < 768); // You can adjust the breakpoint
            };
    
            window.addEventListener('resize', handleResize);
            handleResize(); // Initial check
    
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        const wid=[["100","100","100","100","140","120"],["50","50","50","50","70","60"]]
    
        const ind = isMobile ? 1 : 0;



  return (
    <div>
         <ProgressBar
        percent={percentage}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        width={width}
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width={wid[ind][0]}
              src="/baby.png"
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width={wid[ind][1]}
              src="/babywalker.png"
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width={wid[ind][2]}
              src="/cycle.png"
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width={wid[ind][3]}
              src="/bike.png"
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width={wid[ind][4]}
              src="/car.png"
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width={wid[ind][5]}
              src="/airplane.png"
            />
          )}
        </Step>
      </ProgressBar>
    </div>
  )
}

import logo from './logo.svg';
import './App.css';
import PlacementHome from './Components/PlacementDSACourse/PlacmentHome';
import { useEffect, useState } from 'react';
import EndingMawa from './EndingMawa';

function App() {

  
  
  return (
    <>
   <div style={{"backgroundColor":"#000000", height:"1150vh", color:"white"}}><PlacementHome  /></div>
   <EndingMawa/>
   </>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import Accordian from './Accordian';
import Time from './Time';
import ProgressiveBar from './ProgressiveBar'
export default function DSASheetDataPage({langmini,lang, existsInSet, removeFromSet,addToSet,PlacementData, setchanged,changed, getSetLength}) {
  
  const [localStorageName, setlocalStorageName] = useState(lang)

  function initializeDictionary() {
    if (!localStorage.getItem(localStorageName)) {
      const dictionary = { solved: new Set() ,EnthaTime:"chala"};
      localStorage.setItem(localStorageName, JSON.stringify(dictionary, (key, value) => {
        return key === 'solved' ? [...value] : value;
      }));
    }
  }

  function addToSet(value) {
    const dictionary = JSON.parse(localStorage.getItem(localStorageName));
    if(!dictionary || Object.keys(dictionary.solved).length === 0){
      dictionary.solved = new Set();
    }else{
    dictionary.solved = new Set(dictionary.solved);
    }
    dictionary.solved.add(value);
    updateDictionary(dictionary);
    console.log("vigneshdi", dictionary, value)
  }

  function removeFromSet(value) {
    const dictionary = JSON.parse(localStorage.getItem(localStorageName));
    dictionary.solved = new Set(dictionary.solved);
    dictionary.solved.delete(value);
    updateDictionary(dictionary);
  }

  function existsInSet(value) {
    const dictionary = JSON.parse(localStorage.getItem(localStorageName));
    if (!dictionary || Object.keys(dictionary?.solved)?.length === 0){
      return false;
    }
    dictionary.solved = new Set(dictionary.solved);
    return dictionary.solved.has(value);
  }
  
  function updateDictionary(dictionary) {
    localStorage.setItem(localStorageName, JSON.stringify(dictionary, (key, value) => {
      return key === 'solved' ? [...value] : value;
    }));
  }
  
  function getSetLength() {
    const dictionary = JSON.parse(localStorage.getItem(localStorageName));
    if(!dictionary || Object.keys(dictionary.solved).length === 0){
      return 0;
    }
    dictionary.solved = new Set(dictionary.solved);
    return dictionary.solved.size;
  }
  
  

  useEffect(() => {
    initializeDictionary();
    if(!localStorage.getItem(localStorageName) ){
    window.location.reload();}
  }, [,localStorageName])


  function initializeDictionary1() {
    if (!localStorage.getItem(localStorageName)) {
      const dictionary = { solved: new Set() };
      localStorage.setItem(localStorageName, JSON.stringify(dictionary));
    }
  }
  

  const getDictionary = async( ) => {
    const dictionary = JSON.parse(localStorage.getItem(localStorageName));
    if (!dictionary){
      await initializeDictionary1()
    }else if (!dictionary?.solved || Object.keys(dictionary.solved).length === 0 ) {
      dictionary.solved = new Set();
    } else {
      dictionary.solved = new Set(dictionary.solved);
    }

    if (dictionary && !dictionary?.EnthaTime) {
      dictionary.EnthaTime = 'chala';
    }
    return dictionary;
  }
  function replacerFunction(key, value) {
    return key === 'solved' ? [...value] : value;
  }
  
  function updateDictionary2(dictionary) {
    localStorage.setItem(localStorageName, JSON.stringify(dictionary, replacerFunction));
  }
  

  const  setEnthaTime = async(timeValue) =>{
    const dictionary = await getDictionary();
    dictionary.EnthaTime = timeValue;
    updateDictionary2(dictionary);
  }
  
  // Function to get the EnthaTime variable
  async function getEnthaTime () {
    const dictionary = await getDictionary();
    console.log("dic", dictionary)
    return dictionary.EnthaTime;
  }

  const [time, setTime] = useState("");

  async function GetTime(){
    const timeIgot=await getEnthaTime();
    setTime(timeIgot?timeIgot:'chala');
 
  }
  useEffect(() => {
    GetTime()
  }, [])
  

  const handleTimeChange = (newTime) => {
    setEnthaTime(newTime);
    setTime(newTime);
  };

  let timeMap={"chala":0,"moderate":1,"konchem":2};
  const [percentt, setpercentt] = useState(0);
  const Total=30;

  useEffect(() => {
    let len=getSetLength();
    setpercentt((len/Total)*100)
    console.log("setlen")
  
}, [,changed])




  return (
    <>
     <hr></hr>
     <p className='d-flex justify-content-center my-3'><ProgressiveBar localStorageName={localStorageName} width={"80vw"} percentage={percentt}/></p>
    <div className='container py-3'>
    <div className="row align-items-center mb-3">
    <div className="col-md-10 col-12">
      <h2 className='text-center my-3'>Placement Kosam {langmini} DSA Sheet from Scratch</h2>
    </div>
    <div className="col-md-2 col-12">
      <Time value={time} onChange={handleTimeChange} />
    </div>
  </div>
    <div className="accordion" id="accordionExample">
    {PlacementData.map((item)=>{return <Accordian langmini={langmini} item={item} existsInSet={existsInSet} removeFromSet={removeFromSet} addToSet={addToSet}setchanged={setchanged} changed={changed} timeMap={timeMap} time={time}/>})}
    </div>
    </div>
    </>
  )
}
